import NotesHashtagsDao from '../dao/NotesHashtagsDao';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('NotesHashtagsController.js');

async function set(allHahtagsWithNewOnes) {
  const doc = await getNotehashtagsDataOrDefault();
  doc.noteHashtags = allHahtagsWithNewOnes;
  doc.modifiedAt = Date.now();
  await put(doc);
}

async function put(doc) {
  try {
    await NotesHashtagsDao.write(doc);
  } catch (err) {
    logger.error(`Error while setting noteHashtags: ${err.message}`);
  }
}

async function get() {
  const res = await getNotehashtagsDataOrDefault();
  return res.noteHashtags;
}

async function getNotehashtagsDataOrDefault() {
  try {
    return await NotesHashtagsDao.get();
  } catch (err) {
    return NotesHashtagsDao.getDefaultDoc();
  }
}

export default {
  POST: { set },
  get
};
