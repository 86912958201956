import tools from './../tools';
import agentStorage from './../agentStorage';

const prefix = {
  pub: 'pub',
  compilation: 'compilation',
  compilationItem: 'compitem',
  compilationSectionItem: 'compitem-section',
  customizedBook: 'custombook',
  user: 'user',
  recentBook: 'recentbook',
  course: 'course',
  usernotes: 'usernotes',
  bookmarks: 'bookmarks',
  tags: 'tags',
  notehashtags: 'notehashtags',
  message: 'message',
  flashcard: 'flashcard',
  question: 'question',
  discussion: 'discussion',
  discussionMessage: 'discussionmessage',
  bookProgress: 'bookprogress',
  libraryProgress: 'libraryprogress',
  userSettings: 'usersettings',
  userActivity: 'useractivity',
  favouritePublications: 'favourite-publications'
};

/**
 * @param {string} prefix
 * @return {function(string):string}
 */
function idGenerator(prefixVal) {
  return function(id) {
    return prefixVal + '-' + (id || tools.guid());
  };
}

/**
 *
 * @param {string} prefixVal
 * @returns {string}
 */
function generatorByUserId(prefixVal) {
  return function(userId) {
    return `${prefixVal}-${userId}`;
  };
}

/**
 * @param {string} prefix
 * @return {function(string):string}
 */
function nameGenerator(prefixVal) {
  return function(id) {
    return prefixVal + '_' + (id || tools.guid());
  };
}

function getId(prefixedId) {
  const hasPrefix = Object.values(prefix).some(
    pref => prefixedId.indexOf(pref) === 0
  );
  return hasPrefix ? prefixedId.split('-')[0] : prefixedId;
}

function generateId() {
  return tools.guid();
}

/**
 * This is a generic PouchDB type
 * Define PouchDB instance. Instance should be created by calling 'new PouchDB()'
 * @typedef {object} PouchDB
 * @property {function} allDocs
 * @property {function} bulkDocs
 * @property {function} query
 * @property {function} destroy
 */

/**
 * This is a generic PouchDB type
 * @typedef {object} PouchConfig
 * @property {boolean} [auto_compaction]
 * @property {string} [adapter]
 * @property {number} [revs_limit]
 *
 * @property {boolean} [skip_setup]  (remote only)
 * @property {object}  [ajax]  (remote only)
 *
 * @more https://pouchdb.com/api.html#create_database
 */

/**
 * @typedef {object} PouchDoc
 * @property {string} _id
 * @property {string} [_rev]
 * @property {string} type
 */

/**
 * @typedef {object} PouchMetaDoc
 * @property {string} id
 * @property {string} [key]
 * @property {PouchDoc} [doc]
 */

/**
 * @typedef {Error} PouchError
 * @property {string} error
 * @property {string} message
 * @property {string} name - Error name
 * @property {string} reason - error description
 * @property {number} status - error status code
 */

/**
 * @typedef {object} CouchResponse
 * @property {Array<{_id:string, [_rev]:string, [doc]:CouchDoc}>} rows
 *
 * @todo jsdoc for CouchResponse is partially completed
 *
 */

/**
 * @typedef {object} PouchResult
 *
 * @todo jsdoc for PouchPromise is partially completed
 *
 */

/**
 *
 */
function setUserInfo(info) {
  return agentStorage.setUserInfo(info);
}

export default {
  // public|user|userRW|course is a function!
  public: agentStorage.db.public,
  query: agentStorage.db.query,
  user: agentStorage.db.user,
  userRW: agentStorage.db.userRW,
  course: agentStorage.db.course(nameGenerator(prefix.course)),

  initSync: agentStorage.initSync,
  stopSync: agentStorage.stopSync,
  initCopy: agentStorage.initCopy,
  destroyLocalDB: agentStorage.destroyLocalDB,

  id: {
    pub: idGenerator(prefix.pub),
    compilation: idGenerator(prefix.compilation),
    compilationItem: idGenerator(prefix.compilationItem),
    compilationSectionItem: idGenerator(prefix.compilationSectionItem),
    customizedBook: idGenerator(prefix.customizedBook),
    user: idGenerator(prefix.user),
    recentBook: generatorByUserId(prefix.recentBook),
    course: idGenerator(prefix.course),
    usernotes: idGenerator(prefix.usernotes),
    bookmarks: idGenerator(prefix.bookmarks),
    tags: idGenerator(prefix.tags),
    notehashtags: generatorByUserId(prefix.notehashtags),
    message: idGenerator(prefix.message),
    flashcard: idGenerator(prefix.flashcard),
    question: idGenerator(prefix.question),
    discussion: idGenerator(prefix.discussion),
    discussionMessage: idGenerator(prefix.discussionMessage),
    bookProgress: idGenerator(prefix.bookProgress),
    libraryProgress: idGenerator(prefix.libraryProgress),
    favouritePublications: idGenerator(prefix.favouritePublications),
    userSettings: generatorByUserId(prefix.userSettings),
    userActivity: idGenerator(prefix.userActivity)
  },
  getId: getId,
  generateId: generateId,
  name: {
    course: nameGenerator(prefix.course)
  },
  prefix: prefix,
  setUserInfo: setUserInfo,
  userId: function() {
    return localStorage['_pouch_owner']; //jshint ignore:line
  },
  error: {
    notFound: tools.pouch.notFound
  }
};
