import NoteHashtagsRequestService from '@/services/NoteHashtagsRequestService';
import MaterialsFactory from '@/classes/factories/Materials/MaterialsFactory';
import MaterialTypes from '@/enums/MaterialTypes';

const initState = () => ({
  allNoteHashtags: [],
  updatedNoteHashtags: []
});

const storeGetters = {
  getAllNoteHashtags: state => {
    return state.allNoteHashtags || [];
  },
  getBuildedNoteHashtags: () => noteHashtags => {
    if (!noteHashtags?.length) {
      return [];
    }

    const buildedNoteHashtags = noteHashtags.map(hashtag => {
      const noteHashtagBuilder = MaterialsFactory.createBuilderByType(
        MaterialTypes.NOTE_HASHTAG
      );
      noteHashtagBuilder.setName(hashtag.name);
      if (hashtag.id) {
        noteHashtagBuilder.setId(hashtag.id).setCreatedAt(hashtag.createdAt);
      }

      return noteHashtagBuilder.build();
    });

    return buildedNoteHashtags;
  },
  getUpdatedHashtags: state => {
    return state.updatedNoteHashtags;
  }
};

const actions = {
  initNoteHashtags({ dispatch }, { materials }) {
    let { noteHashtags } = materials;
    dispatch('setNoteHashtags', noteHashtags);
  },
  async addNoteHashtagsForUser({ state, getters, dispatch }, { noteHashtags }) {
    if (!noteHashtags?.length) {
      return;
    }
    const allHashtagsMap = new Map(
      state.allNoteHashtags.map(hashtag => [hashtag.name, { ...hashtag }])
    );
    const buildedNoteHashtags = getters.getBuildedNoteHashtags(noteHashtags);

    buildedNoteHashtags.forEach(noteHashtag => {
      const matchingHashtag = allHashtagsMap.get(noteHashtag.name);

      if (matchingHashtag) {
        matchingHashtag.modifiedAt = noteHashtag.modifiedAt;
        Object.assign(noteHashtag, matchingHashtag);
        allHashtagsMap.set(noteHashtag.name, matchingHashtag);
      } else {
        allHashtagsMap.set(noteHashtag.name, { ...noteHashtag });
      }
    });

    const allHashtags = Array.from(allHashtagsMap.values());
    await NoteHashtagsRequestService.processAddNoteHashtagsRequest(allHashtags);

    dispatch('setUpdatedNoteHashtags', buildedNoteHashtags);
    dispatch('setNoteHashtags', allHashtags);
  },
  setNoteHashtags({ commit }, noteHashtags) {
    commit('setNoteHashtags', noteHashtags);
  },
  setUpdatedNoteHashtags({ commit }, updatedNoteHashtags) {
    commit('setUpdatedNoteHashtags', updatedNoteHashtags);
  },
  clearUpdatedNoteHashtags({ commit }) {
    commit('clearUpdatedNoteHashtags');
  }
};

const mutations = {
  setNoteHashtags(state, noteHashtags) {
    state.allNoteHashtags = noteHashtags;
  },
  setUpdatedNoteHashtags(state, updatedNoteHashtags) {
    state.updatedNoteHashtags = updatedNoteHashtags;
  },
  clearUpdatedNoteHashtags(state) {
    state.updatedNoteHashtags = [];
  }
};

export default {
  state: initState,
  getters: storeGetters,
  actions,
  mutations
};
