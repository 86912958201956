import { localize as $t } from '@/i18n';
import Utils from '@/services/utils/Utils';
import AppModeEnum from '@/enums/AppModeEnum';
import CustomErrors from '@/enums/CustomErrorEnum';
import AnnotationCategoriesEnum from '@/enums/AnnotationCategoriesEnum';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import Locator from '@shared/publication/locator';
import MaterialTypes from '@/enums/MaterialTypes';
import MaterialPositions from '@/enums/MaterialPositions';
import QuizItem from '@/components/views/ExercisesTab/QuizItem/QuizItem';
import FlashcardItem from '@/components/views/ExercisesTab/FlashcardItem/FlashcardItem';
import EssayItem from '@/components/views/ExercisesTab/EssayItem/EssayItem';
import MarkerUtils from '@shared/publication/dom-utils/marker-utils.js';
import now from 'lodash/now';
import RawMaterialFactory from '@/classes/factories/Materials/RawMaterialFactory';

const DEFAULT_CATEGORY_ID = 1;
const BOOKMARK_CLASS_NAME = 'bookmark-cat-31';

class AnnotationBuilder {
  setStart(start) {
    this.start = start;
    return this;
  }

  setEnd(end) {
    this.end = end;
    return this;
  }

  setCategory(category) {
    this.category = category;
    return this;
  }

  setBlockId(blockId) {
    this.blockId = blockId;
    return this;
  }

  setNote(note) {
    this.note = note;
    return this;
  }

  setCreatedAt(createdAt) {
    this.createdAt = createdAt;
    return this;
  }

  setModifiedAt(modifiedAt) {
    this.modifiedAt = modifiedAt;
    return this;
  }

  setId(id) {
    this.id = id;
    return this;
  }

  setStudyGuide(isStudyGuide) {
    this.studyGuide = isStudyGuide;
    return this;
  }

  setIsDeleted(isDeleted) {
    this.isDeleted = isDeleted;
    return this;
  }

  setNoteHashtags(noteHashtags) {
    this.noteHashtags = noteHashtags;
    return this;
  }

  build() {
    return new Annotation(this);
  }
}

class Annotation {
  constructor(annotation, publicationId) {
    this.id = annotation.id || Utils.uuid();
    this.publicationId = publicationId || '';
    this.type = MaterialTypes.ANNOTATION;
    this.studyGuideId = annotation.studyGuideId;
    this.start = this._getDeserializeLocator(annotation.start);
    this.end = this._getDeserializeLocator(annotation.end);
    this.paragraphId = annotation.paragraphId || this.start.prefixedParagraphId;
    this.blockId = annotation.blockId || this.start.prefixedParagraphId;

    this.categoryId = annotation.categoryId || annotation.category;
    this.category =
      annotation.category || annotation.tag || DEFAULT_CATEGORY_ID;
    this.categoryLabel = '';
    this.categoryName = '';
    this.categoryColor = '';

    this.note = annotation.note || '';
    this.quote = '';
    this.highlightedQuote = '';
    this.createdAt = annotation.createdAt || now();
    this.modifiedAt = annotation.modifiedAt || now();
    this.studyGuide = annotation.studyGuide || false;

    this.isDeleted = annotation.isDeleted || false;
    this.isHidden = false;
    this.isSelected = false;
    this.isStackable = false;

    this.initialTop = 0;
    this.top = this.initialTop;
    this.dir = 'ltr';
    this.chapter = '';
    this.annClass = '';

    this.noteHashtags = annotation.noteHashtags || [];
  }

  initAnnotation(container) {
    const annSelector = _getAnnSelector(this.id);
    try {
      const highlightedAnnParts = container.querySelectorAll(annSelector);
      if (!highlightedAnnParts.length) {
        throw CustomErrors.ANNOTATION_RANGE_ERROR;
      }
      this.quote = _getQuote(highlightedAnnParts);
      this.dir = highlightedAnnParts[0]
        .closest(`.${AppConstantsUtil.SCROLL_ITEM_WRAPPER_CLASS}`)
        .getAttribute('dir');
      this.initialTop = _getAnnotationTopOffset(highlightedAnnParts);
      this.top = this.initialTop;
    } catch (err) {
      throw CustomErrors.ANNOTATION_RANGE_ERROR;
    }
  }

  reinitTopOffset(container) {
    const annSelector = _getAnnSelector(this.id);
    const highlightedParts = container.querySelectorAll(annSelector);
    this.initialTop = _getAnnotationTopOffset(highlightedParts);
    this.top = this.initialTop;
  }

  reinitLocatorPoints(paraId) {
    this.start = new Locator.InTextLocator(
      paraId,
      this.start.logicalCharOffset
    );
    this.end = new Locator.InTextLocator(paraId, this.end.logicalCharOffset);
  }

  changeNote(note) {
    this.note = note;
    return this;
  }

  changeCategory(category) {
    this.categoryId = category;
    this.category = category;
    return this;
  }

  changeCategoryColor(categoryColor) {
    this.categoryColor = categoryColor;
    return this;
  }

  changeCategoryLabel(categoryLabel) {
    this.categoryLabel = categoryLabel;
    return this;
  }

  changeCategoryName(categoryName) {
    this.categoryName = categoryName;
    return this;
  }

  changeCreationTime(createdAt) {
    this.createdAt = createdAt;
    return this;
  }

  changeModificationTime(modifiedAt) {
    this.modifiedAt = modifiedAt;
    return this;
  }

  createAnnotationView(publicationId) {
    return new Annotation(this, publicationId);
  }

  createRawMaterial() {
    return RawMaterialFactory.createRawAnnotation(this);
  }

  markAsDeleted() {
    this.isDeleted = true;
  }

  _getDeserializeLocator(locator) {
    return typeof locator === 'string' ? Locator.deserialize(locator) : locator;
  }

  getHighlightData() {
    return {
      prefix: 'ann',
      readerClass: 'nota-annotation-reader',
      idAttribute: 'data-annotation-id'
    };
  }
}

class BookmarkBuilder {
  setId(id) {
    this.id = id;
    return this;
  }

  setBlockId(blockId) {
    this.blockId = blockId;
    return this;
  }

  setLocator(locator) {
    this.locator = locator;
    return this;
  }

  setParaNum(paraNum) {
    this.paraNum = paraNum;
    return this;
  }

  setCreatedAt(createdAt) {
    this.createdAt = createdAt;
    return this;
  }

  build() {
    return new Bookmark(this);
  }
}

class Bookmark {
  constructor(bookmark) {
    this.id = bookmark.id || Utils.uuid();
    this.type = MaterialTypes.BOOKMARK;
    this.paraNum = bookmark.paraNum || '';
    this.locator = this._getDeserializeLocator(bookmark.locator);
    this.createdAt = bookmark.createdAt || now();
    this.paragraphId =
      bookmark.paragraphId || this.locator.startLocator.prefixedParagraphId;
    this.blockId =
      bookmark.blockId || this.locator.startLocator.prefixedParagraphId;
    this.categoryClass = BOOKMARK_CLASS_NAME;
    this.highlightedQuote = bookmark.highlightedQuote || '';
  }

  _getDeserializeLocator(locator) {
    return typeof locator === 'string' ? Locator.deserialize(locator) : locator;
  }

  getHighlightData() {
    return {
      prefix: 'mark',
      readerClass: 'mark-bookmark-reader',
      idAttribute: 'data-bookmark-id'
    };
  }

  createRawMaterial() {
    return RawMaterialFactory.createRawBookmark(this);
  }
}

class NoteHashtagBuilder {
  setId(id) {
    this.id = id;
    return this;
  }

  setName(name) {
    this.name = name;
    return this;
  }

  setCreatedAt(createdAt) {
    this.createdAt = createdAt;
    return this;
  }

  setModifiedAt(modifiedAt) {
    this.modifiedAt = modifiedAt;
    return this;
  }

  build() {
    return new NoteHashtag(this);
  }
}

class NoteHashtag {
  constructor(noteHashtag) {
    this.id = noteHashtag.id || Utils.uuid();
    this.type = MaterialTypes.NOTE_HASHTAG;
    this.name = noteHashtag.name || '';
    this.createdAt = noteHashtag.createdAt || now();
    this.modifiedAt = noteHashtag.modifiedAt || now();
  }

  createRawMaterial() {
    return RawMaterialFactory.createRawNoteHashtag(this);
  }
}

class ParaNoteBuilder {
  setParagraphId(paragraphId) {
    this.paragraphId = paragraphId;
    return this;
  }

  setNote(note) {
    this.note = note;
    return this;
  }

  setPosition(position) {
    this.position = position;
    return this;
  }

  setCreatedAt(createdAt) {
    this.createdAt = createdAt;
    return this;
  }

  setModifiedAt(modifiedAt) {
    this.modifiedAt = modifiedAt;
    return this;
  }

  setId(id) {
    this.id = id;
    return this;
  }

  build() {
    return new ParaNote(this);
  }
}
class ParaNote {
  constructor(paraNote) {
    this.id = paraNote.id || Utils.uuid();
    this.type = MaterialTypes.PARA_NOTE;
    this.paragraphId = paraNote.paragraphId;
    this.note = paraNote.note || '';
    this.position = paraNote.position || MaterialPositions.AFTER_PARAGRAPH;
    this.createdAt = paraNote.createdAt || now();
    this.modifiedAt = paraNote.modifiedAt || now();
  }

  changeNote(note) {
    this.note = note;
    return this;
  }

  changePosition(position) {
    this.position = position;
    return this;
  }

  changeId(id) {
    this.id = id;
  }

  createRawMaterial() {
    return RawMaterialFactory.createRawParaNote(this);
  }
}

class TestBuilder {
  setId(id) {
    this._id = id;
    return this;
  }

  setQuestionsCount(count) {
    this.testQuestionsCount = count;
    return this;
  }

  setPublicationId(publicationId) {
    this.publicationId = publicationId;
    return this;
  }

  setParagraphNumber(paraNumber) {
    this.paragraphNumber = paraNumber;
    return this;
  }

  setOriginalId(originalId) {
    this.originalId = originalId;
    return this;
  }

  setName(name) {
    this.name = name;
    return this;
  }

  setCreatedAt(createdAt) {
    this.createdAt = createdAt;
    this.modifiedAt = createdAt;
    return this;
  }

  setParagraphId(paragraphId) {
    this.locator = paragraphId;
    return this;
  }

  setDescription(description) {
    this.description = description;
    return this;
  }

  setTestQuestions(questions) {
    this.testQuestions = questions;
    return this;
  }
}

class Test {
  constructor(test) {
    this.id = test._id || Utils.uuid();
    this.paragraphId = test.locator;
    this.publicationId = test.publicationId;
    this.createdAt = test.createdAt || now();
    this.modifiedAt = test.modifiedAt || now();
    this.testQuestions = test.testQuestions || [];
    this.name = test.name || '';
    this.description = test.description || '';
    this.testQuestionsCount = test.testQuestionsCount || 0;
  }

  createTestEditorView() {
    return new TestEditorView(this);
  }

  changeTestQuestions(questions) {
    if (!questions) {
      return;
    }
    this.testQuestions = questions;
  }

  isTestInfoComplete() {
    return this.testQuestions.length > 0;
  }
}

class QuizBuilder extends TestBuilder {
  build() {
    return new Quiz(this);
  }
}

class Quiz extends Test {
  constructor(quiz) {
    super(quiz);
    this.type = MaterialTypes.QUIZ;
  }

  createExerciseListView(appMode) {
    const exerciseListViewBuilder = new ExercisesListViewBuilder();
    return exerciseListViewBuilder
      .setId(this.id)
      .setParaId(this.paragraphId)
      .setName(this.name)
      .setDescription(this.description)
      .setCount(this.testQuestionsCount)
      .setType(this.type)
      .setAppMode(appMode)
      .build();
  }

  createRawMaterial() {
    return RawMaterialFactory.createRawTest(this);
  }
}

class FlashcardBuilder extends TestBuilder {
  build() {
    return new Flashcard(this);
  }
}
class Flashcard extends Test {
  constructor(flashcard) {
    super(flashcard);
    this.type = MaterialTypes.FLASHCARD;
  }

  createExerciseListView(appMode) {
    const exerciseListViewBuilder = new ExercisesListViewBuilder();
    return exerciseListViewBuilder
      .setId(this.id)
      .setParaId(this.paragraphId)
      .setName(this.name)
      .setDescription(this.description)
      .setCount(this.testQuestionsCount)
      .setType(this.type)
      .setAppMode(appMode)
      .build();
  }

  createRawMaterial() {
    return RawMaterialFactory.createRawTest(this);
  }
}

class EssayTaskBuilder {
  setWordsLimit(limit) {
    this.wordsLimit = limit;
    return this;
  }

  setTopic(topic) {
    this.topic = topic;
    return this;
  }

  setComment(comment) {
    this.comment = comment;
    return this;
  }

  setPublicationId(pubId) {
    this.publicationId = pubId;
    return this;
  }

  setParagraphId(paragraphId) {
    this.locator = {
      paragraphId
    };
    return this;
  }

  setCreatedAt(createdAt) {
    this.createdAt = createdAt;
    return this;
  }

  setId(id) {
    this._id = id;
    return this;
  }

  build() {
    return new EssayTask(this);
  }
}
class EssayTask {
  constructor(essayTask) {
    this.id = essayTask._id || Utils.uuid();
    this.type = MaterialTypes.ESSAY_TASK;
    this.createdAt = essayTask.createdAt || now();
    this.modifiedAt = essayTask.modifiedAt || now();
    this.publicationId = essayTask.publicationId;
    this.paragraphId = essayTask.locator.paragraphId;
    this.topic = essayTask.topic;
    this.comment = essayTask.comment || '';
    this.wordsLimit = essayTask.wordsLimit;
  }

  createExerciseListView(appMode) {
    const exerciseListViewBuilder = new ExercisesListViewBuilder();
    return exerciseListViewBuilder
      .setId(this.id)
      .setParaId(this.paragraphId)
      .setName(this.topic)
      .setDescription(this.comment)
      .setCount(this.wordsLimit)
      .setType(MaterialTypes.ESSAY_TASK)
      .setAppMode(appMode)
      .build();
  }

  createRawMaterial() {
    return RawMaterialFactory.createRawEssayTask(this);
  }
}

function _getAnnSelector(annId) {
  return `[${MarkerUtils.getAnnotationIdMarker()}="${annId}"]`;
}

function _getQuote(highlightedAnnParts) {
  let quote = '';
  const annotationParts = Array.from(highlightedAnnParts);
  const annPartsCount = annotationParts.length;
  for (let i = 0; i < annPartsCount; i++) {
    quote += annotationParts[i].textContent;
  }
  return quote;
}

function _getAnnotationTopOffset(highlightedAnnParts) {
  if (!highlightedAnnParts.length) {
    return;
  }
  let highlightedPart = highlightedAnnParts[0];
  const isFirstHighlightedInitialLetter = highlightedPart.closest(
    '[dropcap-dummy]'
  );
  if (isFirstHighlightedInitialLetter && highlightedAnnParts.length > 1) {
    highlightedPart = highlightedAnnParts[1];
  }
  const annTopOffest = Math.max(
    highlightedPart.offsetTop,
    highlightedPart.offsetParent.offsetTop
  );

  return annTopOffest;
}

class ExercisesListViewBuilder {
  setId(id) {
    this.id = id;
    return this;
  }

  setParaId(paraId) {
    this.paraId = paraId;
    return this;
  }

  setName(name) {
    this.name = name;
    return this;
  }

  setDescription(description) {
    this.description = description;
    return this;
  }

  setCount(count) {
    this.count = count;
    return this;
  }

  setType(type) {
    this.type = type;
    return this;
  }

  setAppMode(appMode) {
    this.appMode = appMode;
    return this;
  }

  build() {
    return new ExersiceListView(this);
  }
}

class ExersiceListView {
  constructor(buildData) {
    this.id = buildData.id;
    this.paraId = buildData.paraId;
    this.name = buildData.name;
    this.description = buildData.description;
    this.type = buildData.type;
    this.chapter = '';
    this.paraNum = '';
    this.component = getComponentByExerciseType(this.type);
    this.count = buildData.count;
    if (buildData.appMode === AppModeEnum.READER) {
      this.isCompleted = buildData.isCompleted || false;
    }
  }

  setChapter(chapter) {
    this.chapter = chapter;
  }

  setParaNum(paraNum) {
    this.paraNum = paraNum;
  }

  changeDescription(description) {
    this.description = description;
  }
}

function getComponentByExerciseType(type) {
  switch (type) {
    case MaterialTypes.QUIZ:
      return QuizItem;
    case MaterialTypes.FLASHCARD:
      return FlashcardItem;
    case MaterialTypes.ESSAY_TASK:
      return EssayItem;
  }
}

const DEFAULT_UNDERLINE_TYPE = 'pencilUnderline';

class Category {
  constructor(params) {
    this.id = params.id;
    this.label = params.label;
    this.name = (params.name || '').substring(
      0,
      AppConstantsUtil.MAX_CATEGORY_NAME_LENGTH
    );
    this.underline = params.underline && DEFAULT_UNDERLINE_TYPE;
    this.color = params.color;
    this.nightColor = params.nightColor || params.color;
    this.version = params.version || 1;
    this.editorOnly = false;
    this.isDefault = params.isDefault || false;
    this.isDeleted = params.isDeleted || false;
    this.modifiedAt = params.modifiedAt || now();
  }

  createRawMaterial() {
    if (this.isDefault) {
      return RawMaterialFactory.createRawDefaultCategory(this);
    }

    return RawMaterialFactory.createRawNewCategory(this);
  }
}

function getBookmarkCategoryStyles() {
  return {
    [`.${BOOKMARK_CLASS_NAME}`]: {
      background: 'none',
      boxShadow: 'none',
      'background-color': '#fbc333',
      cursor: 'pointer'
    }
  };
}

function getDefaultCategories() {
  return [
    {
      id: 1,
      name: AnnotationCategoriesEnum.IMPORTANT,
      label: $t('Annotations.type.Important'),
      color: '#fefe3c',
      nightColor: '#8b640e',
      preset: true,
      editorOnly: false,
      defaultCategory: true
    },
    {
      id: 2,
      name: AnnotationCategoriesEnum.DATE_OR_NAME,
      label: $t('Annotations.type.Date or name'),
      color: '#b9e7ff',
      nightColor: '#1f709a',
      preset: true,
      editorOnly: false
    },
    {
      id: 3,
      name: AnnotationCategoriesEnum.QUESTION,
      label: $t('Annotations.type.Question'),
      color: '#b8e5cb',
      nightColor: '#366d40',
      preset: true,
      editorOnly: false
    },
    {
      id: 4,
      name: AnnotationCategoriesEnum.CRITICAL,
      label: $t('Annotations.type.Critical'),
      color: '#ffd6e8',
      nightColor: '#8c3d60',
      preset: true,
      editorOnly: false
    },
    {
      id: 5,
      name: AnnotationCategoriesEnum.VOCABULARY,
      label: $t('Annotations.type.Vocabulary'),
      underline: 'pencilUnderline',
      color: '#697fbe',
      nightColor: '#697fbe',
      editorOnly: true
    }
  ];
}

class CategoryBuilder {
  setLabel(label) {
    this.label = label;
    return this;
  }

  setName(name) {
    this.name = name;
    return this;
  }

  setUnderline(underline) {
    this.underline = underline;
    return this;
  }

  setColor(color) {
    this.color = color;
    return this;
  }

  setVersion(version) {
    this.version = version;
    return this;
  }

  setNightColor(nightColor) {
    this.nightColor = nightColor;
    return this;
  }

  setId(id) {
    this.id = id;
    return this;
  }

  setDefault(isDefault) {
    this.isDefault = isDefault;
    return this;
  }

  setModifiedAt(modifiedAt) {
    this.modifiedAt = modifiedAt;
    return this;
  }

  setIsDeleted(isDeleted) {
    this.isDeleted = isDeleted;
    return this;
  }

  build() {
    return new Category(this);
  }
}

class TestSettings {
  constructor(params) {
    this.testLocalizedName = params.testLocalizedName;
    this.maxQuestionsLength = params.maxQuestionsLength;
    this.testIsBritishNationalCorpus = params.testIsBNC;
  }
}
class TestEditorView {
  constructor(test) {
    this.id = test.id;
    this.name = test.name;
    this.bookId = test.publicationId;
    this.description = test.description;
    this.locator = test.paragraphId;
    this.testQuestions = test.testQuestions.map(
      question => new TestQuestionView(question)
    );
    this.currentQuestionIndex = 0;
    this.testType = test.type;
    this.testQuestionsCount = test.testQuestionsCount;
    this.createdAt = test.createdAt;
  }

  changeName(name) {
    this.name = name;
  }

  changeDescription(description) {
    this.description = description;
  }

  addQuestion(question) {
    this.testQuestions.push(new TestQuestionView(question));
    this.increaseQuestionsCount();
  }

  deleteQuestion(questionId) {
    this.testQuestions = this.testQuestions.filter(
      question => question.id !== questionId
    );
    this.decreaseQuestionsCount();
  }

  addEmptyQuestion() {
    this.addQuestion(new TestQuestionView());
  }

  increaseQuestionsCount() {
    this.testQuestionsCount++;
  }

  decreaseQuestionsCount() {
    this.testQuestionsCount--;
  }
}

class BNCTestView {
  constructor(params) {
    this.name = params.name;
    this.description = params.description;
    this.testQuestions = params.questions.map(
      question => new BNCTestQuestion(question)
    );
    this.currentQuestionIndex = 0;
    this.testType = params.type;
    this.testQuestionsCount = params.questionsCount;
  }

  setQuestions(questions) {
    this.testQuestions = questions;
  }

  createRawMaterial() {
    return this;
  }

  addQuestion(question) {
    this.testQuestions.push(new BNCTestQuestion(question));
    this.increaseQuestionsCount();
  }

  deleteQuestion(questionId) {
    this.testQuestions = this.testQuestions.filter(
      question => question.id !== questionId
    );
    this.decreaseQuestionsCount();
  }

  addEmptyQuestion() {
    const params = BNCTestQuestion.getEmptyQuestionParams();
    this.addQuestion(new BNCTestQuestion(params));
  }

  increaseQuestionsCount() {
    this.testQuestionsCount++;
  }

  decreaseQuestionsCount() {
    this.testQuestionsCount--;
  }
}

class TestQuestionView {
  constructor(testQuestion = {}) {
    this.question = testQuestion.question || '';
    this.id = testQuestion._id || Utils.uuid();
    this.answers = testQuestion.incorrectAnswers
      ? [testQuestion.answer, ...Object.values(testQuestion.incorrectAnswers)]
      : Array(4).fill('');
    this.audio = testQuestion.audio;
    this.image = testQuestion.image;
    this.correctAnswerIndex = 0;
  }

  changeQuestion(question) {
    this.question = question;
  }

  addImage(image) {
    this.image = image;
  }

  addAudio(audio) {
    this.audio = audio;
  }

  changeCorrectAnswer(answer) {
    this.answers[this.correctAnswerIndex] = answer;
  }

  changeIncorrectAnswerByIndex(answer, index) {
    this.answers[index + 1] = answer;
  }
}

class BNCTestQuestion extends TestQuestionView {
  constructor(params = {}) {
    super(params);
    this._id = params._id || Utils.uuid();
    this.id = this._id;
    this.level = params.level;
    this.word = params.word;
  }

  static getEmptyQuestionParams() {
    return {
      word: '',
      question: '',
      answer: '',
      incorrectAnswers: ['', '', '']
    };
  }
}

class BNCTestQuestionServer {
  constructor(params) {
    this._id = params._id;
    this.word = params.word;
    this.level = params.level || null;
    this.question = params.question;
    this.answer = params.answer;
    this.incorrectAnswers = params.incorrectAnswers;
  }

  static createServerQuestion(params) {
    const answer = params.answers[params.correctAnswerIndex];
    params.answers.splice(params.correctAnswerIndex, 1);

    Object.assign(params, {
      answer,
      incorrectAnswers: params.answers
    });

    return new BNCTestQuestionServer(params);
  }
}

function getBNCTestQuestionServerClass() {
  return BNCTestQuestionServer;
}

function createTestEditorView(params, bookId) {
  return new TestEditorView(params, bookId);
}

function createBNCTestView(params) {
  return new BNCTestView(params);
}

function createTestSettings(params) {
  return new TestSettings(params);
}

function createExersiceListView(exercise, appMode) {
  return new ExersiceListView(exercise, appMode);
}

function createCategoryBuilder() {
  return new CategoryBuilder();
}

function createMaterialByType({ material, type }) {
  const expectNoteProperties = ['blockId', 'start', 'end', 'note', 'category'];
  const isAnnotation = expectNoteProperties.every(expProp =>
    material.hasOwnProperty(expProp)
  );
  if (isAnnotation && !material.hasOwnProperty('type')) {
    material.type = MaterialTypes.ANNOTATION;
    type = MaterialTypes.ANNOTATION;
  }

  switch (type) {
    case MaterialTypes.ANNOTATION:
      return new Annotation(material);
    case MaterialTypes.PARA_NOTE:
      return new ParaNote(material);
    case MaterialTypes.QUIZ:
      return new Quiz(material);
    case MaterialTypes.ESSAY_TASK:
      return new EssayTask(material);
    case MaterialTypes.FLASHCARD:
      return new Flashcard(material);
    case MaterialTypes.BOOKMARK:
      return new Bookmark(material);
    case MaterialTypes.NOTE_HASHTAG:
      return new NoteHashtag(material);
  }
}

function createBuilderByType(type) {
  switch (type) {
    case MaterialTypes.PARA_NOTE:
      return new ParaNoteBuilder();
    case MaterialTypes.BOOKMARK:
      return new BookmarkBuilder();
    case MaterialTypes.ANNOTATION:
      return new AnnotationBuilder();
    case MaterialTypes.QUIZ:
      return new QuizBuilder();
    case MaterialTypes.ESSAY_TASK:
      return new EssayTaskBuilder();
    case MaterialTypes.FLASHCARD:
      return new FlashcardBuilder();
    case MaterialTypes.NOTE_HASHTAG:
      return new NoteHashtagBuilder();
  }
}

export default {
  createTestSettings,
  createTestEditorView,
  createBNCTestView,
  getBNCTestQuestionServerClass,
  createBNCTestQuestionServer: BNCTestQuestionServer.createServerQuestion,
  getEmptyQuestionParams: BNCTestQuestion.getEmptyQuestionParams,
  createMaterialByType,
  createBuilderByType,
  createCategoryBuilder,
  createExersiceListView,
  getDefaultCategories,
  getBookmarkCategoryStyles
};
