<template>
  <div
    ref="annotationChip"
    :dir="highlightedQuote.dir"
    class="chip-item"
    :class="chipClass"
    @click="processClick"
  >
    <div class="chip-item-content" :style="chipItemStyles">
      <div
        class="chip-item-text chip-text-content"
        dir="auto"
        v-html="chipText"
      />
      <div v-if="showCommentIcon || showTagIcon" class="ico-block">
        <BaseSpriteIcon v-if="showCommentIcon" icon-name="ico-message-round" />
        <BaseSpriteIcon v-if="showTagIcon" icon-name="ico-tag-notes" />
      </div>
    </div>
  </div>
</template>

<script>
import searchHighlighter from '@shared/publication/search-highlighter';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import highlightedQuoteMixin from '@/components/mixins/highlightedQuoteMixin';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import PromiseUtil from '@/services/utils/PromiseUtil';

export default {
  name: 'AnnotationChipItem',
  components: { BaseSpriteIcon },
  mixins: [highlightedQuoteMixin],
  props: {
    annotation: Object,
    filterText: String,
    clickAction: {
      type: Boolean,
      default: true
    },
    showCommentIcon: {
      type: Boolean,
      default: false
    },
    showTagIcon: {
      type: Boolean,
      default: false
    },
    showNoteMode: {
      type: Boolean,
      default: false
    },
    insideBook: {
      type: Boolean,
      default: false
    },
    chipContentClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      highlightedQuote: {},
      isClicked: false
    };
  },
  computed: {
    categoryColor() {
      return this.$store.getters['CategoriesStore/getCategoryColorById'](
        this.annotation.categoryId
      );
    },
    bookId() {
      const openParams = this.$store.getters[
        'OpenParameterStore/getPublicationOpenParameters'
      ];
      return openParams?.publicationId;
    },
    chipItemStyles() {
      return { 'border-inline-start': `5px solid ${this.categoryColor}` };
    },
    chipText() {
      return this.showNoteMode
        ? this.annotation.note
        : this.highlightedQuote.text;
    },
    getActiveChipData() {
      return this.$store.getters['AnnotationsStore/getActiveChipsData'];
    },
    isActiveByPosition() {
      return (
        this.isClicked ||
        (this.insideBook && !this.getActiveChipData?.openFromExtras)
      );
    },
    isActive: {
      get: function() {
        const isActiveById = this.getActiveChipData?.annIds?.includes(
          this.annotation.id
        );
        return isActiveById && this.isActiveByPosition;
      }
    },
    chipClass() {
      const chipClasses = [`chip-${this.annotation.id}`];
      if (this.isActive) {
        chipClasses.push('active');
      }
      if (this.chipContentClass) {
        chipClasses.push(this.chipContentClass);
      }
      return chipClasses;
    }
  },
  watch: {
    filterText(newText) {
      this.decorateText(newText);
    },
    isActive(val) {
      if (!val) {
        this.isClicked = false;
      }
    }
  },
  async mounted() {
    this.applyAnnotationData();
    if (typeof this.filterText === 'string') {
      this.decorateText(this.filterText);
    }
  },
  beforeDestroy() {
    this.$_undecorateHighlight();
  },
  methods: {
    decorateText(newText) {
      this.$_undecorateHighlight();
      if (newText) {
        this.$_applyHighlight(newText);
      }
    },
    isInfoPopupOpened() {
      return this.$store.getters['ManagePopupStore/isPopupOpened'](
        PopupNamesEnum.ANNOTATION_INFO_POPUP
      );
    },
    async processClick() {
      if (!this.clickAction) {
        return;
      }

      if (!this.annotation) {
        this.$store.dispatch('ManagePopupStore/openToaster', {
          text: this.$t('PresentPublication.error.infoPopup')
        });
        return;
      }
      this.isClicked = !this.isClicked;

      if (this.isInfoPopupOpened()) {
        this.$store.dispatch('ManagePopupStore/closePopup', {
          name: PopupNamesEnum.ANNOTATION_INFO_POPUP
        });
        await PromiseUtil.wait(100);
      }

      let annIds = this.isClicked ? [this.annotation.id] : [];
      this.$store.dispatch('AnnotationsStore/setActiveAnnIds', {
        annIds,
        openFromExtras: this.isCurrentChipInExtras()
      });

      if (this.isClicked) {
        this.openAnnInfoPopup();
      }
    },
    isCurrentChipInExtras() {
      return !!this.$el?.closest(`.${AppConstantsUtil.EXTRAS_POPUP}`);
    },
    openAnnInfoPopup() {
      const openFromExtras = this.isCurrentChipInExtras();

      this.$store.dispatch('ManagePopupStore/openPopup', {
        name: PopupNamesEnum.ANNOTATION_INFO_POPUP,
        popupContext: {
          annotationIds: [this.annotation.id],
          firstAttachableElementClass: 'chip-item.active',
          secondAttachableElementClass: 'chip-item.active',
          blockId: this.annotation.blockId,
          paraId: this.annotation.paragraphId,
          openFromExtras
        }
      });
    },
    async applyAnnotationData() {
      const annotationHighlightedQuote = this.annotation?.highlightedQuote;
      this.highlightedQuote =
        annotationHighlightedQuote ||
        (await this.$_getHighlightedQuote(this.bookId, this.annotation));
    },
    $_applyHighlight(quote) {
      const el = document.createElement('div');
      el.innerHTML = this.highlightedQuote.text;
      searchHighlighter.decorateSearchQuotes(
        [[[quote]]],
        el,
        AppConstantsUtil.NOTE_FILTER_HIGHLIGHT_CLASS
      );
      this.highlightedQuote.text = el.innerHTML;
    },
    $_undecorateHighlight() {
      const el = document.createElement('div');
      el.innerHTML = this.highlightedQuote.text;
      searchHighlighter.undecorateByClass(
        AppConstantsUtil.NOTE_FILTER_HIGHLIGHT_CLASS,
        el
      );
      this.highlightedQuote.text = el.innerHTML;
    }
  }
};
</script>

<style lang="less" src="./AnnotationChipItem.less" />
