<template>
  <div ref="NotesTabContainer" class="notes-tab-wrapper">
    <FilterItem
      ref="filterItem"
      :class="{ disabled: isFilterDisabled }"
      :external-data="{ items: categoriesLabels }"
      @onAction="onFilter"
    />
    <FakeProgressLine :task="progressTask" />

    <div class="notes-wrapper">
      <component
        :is="data.component"
        v-for="data in shownItems"
        :key="data.externalItemData.id"
        :filter-text="filterText"
        :external-data="data.externalItemData"
        :annotation="data.annotation"
        :show-comment-icon="!!data?.annotation?.note"
        :show-tag-icon="!!data?.annotation?.noteHashtags?.length"
        @onAction="processAction"
      />
      <NoDataItem
        v-if="!shownItems.length && !isFilterActive"
        :content-image="noDataContentImage"
        :content-title="$t('Annotations.noResults.header')"
        :content-message="$t('Annotations.noResults.message')"
      ></NoDataItem>
      <FilterNoItems
        v-if="!shownItems.length && isFilterActive"
        :content-title="$t('Filter.noNotes.msg')"
      ></FilterNoItems>
    </div>
  </div>
</template>

<script>
import LoggerFactory from '@/services/utils/LoggerFactory';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
const logger = LoggerFactory.getLogger('NotesTab.vue');

import AnnotationChipItem from './AnnotationChipItem/AnnotationChipItem';
import ChapterItem from './ChapterItem/ChapterItem';
import FilterItem from './FilterItem/FilterItem';
import FakeProgressLine from '@/components/base/FakeProgressLine/FakeProgressLine.vue';
import NoDataItem from './../NoDataItem/NoDataItem';
import FilterNoItems from './FilterNoItems/FilterNoItems';

import { mapGetters } from 'vuex';

import highlightedQuoteMixin from '@/components/mixins/highlightedQuoteMixin';

import FakeProgressLineTaskEnum from '@/enums/FakeProgressLineTaskEnum';
import NotesTabActionsEnum from '@/enums/NotesTabActionsEnum';
import PublicationsTypesEnum from '@shared/enums/PublicationsTypesEnum';
import AppModeEnum from '@/enums/AppModeEnum';
import PopupNamesEnum from '@/enums/PopupNamesEnum';

import images from '@/assets/images';

import groupBy from 'lodash/groupBy';

let DATA_SHOWN_TYPES = {
  NO_ANNOTATIONS: 'noAnnotations',
  ANNOTATION: 'annotation',
  CHAPTER_NAME: 'chapterName'
};

const SHOW_ANNS_NUM = 40;
const HEIGHT_TO_START_LOAD = 200;

class TabItemView {
  constructor(data) {
    this.type = data.type;
    this.annotation = data.annotation || null;
    this.externalItemData = data.externalItemData || {};
    this.isShown = data.isShown;
    this.isFiltered = data.isFiltered;
    this.component = data.component;
  }
}

class TabItemViewBuider {
  setShownType(type) {
    this.type = type;
    return this;
  }

  setAnnotation(annotation) {
    this.annotation = annotation;
    return this;
  }

  setExternalData(data) {
    this.externalItemData = data;
    return this;
  }

  setIsShown(isShown) {
    this.isShown = isShown;
    return this;
  }

  setIsFiltered(isFiltered) {
    this.isFiltered = isFiltered;
    return this;
  }

  setComponent(component) {
    this.component = component;
    return this;
  }

  build() {
    return new TabItemView(this);
  }
}

export default {
  name: 'NotesTab',
  components: {
    AnnotationChipItem,
    ChapterItem,
    FakeProgressLine,
    FilterItem,
    NoDataItem,
    FilterNoItems,
    images
  },
  mixins: [highlightedQuoteMixin],
  data() {
    return {
      flattenedAnnotations: [],
      lastShownIndex: SHOW_ANNS_NUM,
      externalItemData: {},
      filterText: '',
      allItems: [],
      activeChapter: '',
      activeCategory: '',
      isAllAnnotationsHighlighted: false,
      isDestroyed: false,
      endSubscription: null,
      progressTask: '',
      noDataContentImage: images.picTocEmpty
    };
  },
  computed: {
    extrasContext() {
      return this.$store.getters['ManagePopupStore/getPopupContext'](
        PopupNamesEnum.EXTRAS_POPUP
      );
    },
    extrasDetails() {
      const appMode = this.$store.getters['ContextStore/appModeGetter'];
      return {
        isShownStudyNotes: appMode === AppModeEnum.READER,
        isShownClassAnnotations:
          this.extrasContext.publicationType ===
          PublicationsTypesEnum.STUDY_COURSE
      };
    },
    bookId() {
      const openParams = this.$store.getters[
        'OpenParameterStore/getPublicationOpenParameters'
      ];
      return openParams?.publicationId;
    },
    ...mapGetters({
      getFlattenedAnns: 'AnnotationsStore/getFlattenedAnnListViews',
      getParaNum: 'BookStore/getParaNumByParaId',
      getChapterName: 'BookStore/getChapterNameByParaId',
      getCategoryName: 'CategoriesStore/getCategoryNameById',
      getCategoryLabel: 'CategoriesStore/getCategoryLabelById',
      getCategoryColor: 'CategoriesStore/getCategoryColorById',
      getAnnClass: 'CategoriesStore/getAnnClassByCategoryId',
      getCategories: 'CategoriesStore/getAllCategories'
    }),
    getMaterialsLoaded() {
      return this.$store.getters['MaterialsStore/getMaterialsLoaded']();
    },
    isFilterDisabled() {
      return !this.shownItems?.length && !this.isFilterActive;
    },
    categories() {
      return this.getCategories;
    },
    ownAnnotations() {
      return this.flattenedAnnotations.filter(
        annotation => !annotation.studyGuide
      );
    },
    categoriesLabels() {
      let categoriesLabels = this.categories.map(cat => {
        const categoryText = cat.isDefault ? this.$t(cat.label) : cat.name;
        return {
          text: categoryText,
          value: cat.name,
          color: cat.color,
          nightColor: cat.nightColor
        };
      });
      let allCategoryLabel = {
        text: this.$t('Categories.allCategories'),
        value: '',
        color: ''
      };
      return [allCategoryLabel, ...categoriesLabels];
    },
    shownItems() {
      const shownItems = [];
      const startIndex = 0;
      for (let i = startIndex; i < this.allItems.length; i++) {
        const item = this.allItems[i];
        if (!item.isShown || !item.isFiltered) {
          continue;
        }
        let nextItem = this.allItems[i + 1];
        item.externalItemData.isNoResults = !nextItem;

        shownItems.push(item);
        if (shownItems.length > this.lastShownIndex) {
          break;
        }
      }

      return shownItems;
    },
    isFilterActive() {
      return this.activeCategory || this.filterText;
    }
  },
  watch: {
    getMaterialsLoaded(isLoaded) {
      if (isLoaded) {
        this.stopProgress();
      }
    }
  },
  async mounted() {
    if (!this.getMaterialsLoaded) {
      this.startProgress();
    }
    await this.$_applyFlattenedAnnotations();
    const extrasContainer = document.querySelector(
      `.${AppConstantsUtil.EXTRAS_SCROLLABLE_CLASS}`
    );
    this.allItems = await this.$_getAllData();
    extrasContainer.addEventListener('scroll', data => {
      this.onExtrasScroll(data);
    });
    this.$_subscribe();
    this.$_fillAllAnnotations();
  },
  destroyed() {
    this.isDestroyed = true;
    this.stopProgress();
    if (this.endSubscription) {
      this.endSubscription();
    }
  },
  methods: {
    async $_fillAllAnnotations(index = 0) {
      try {
        index += 1;
        if (
          index === this.allItems.length ||
          this.isDestroyed ||
          !this.allItems.length
        ) {
          return;
        }

        return this.$_fillAllAnnotations(index);
      } catch (error) {
        logger.error(
          `Get error on fill annotation highlighted quote bookId:${this.bookId} error:${error}`
        );
        return this.$_fillAllAnnotations(index);
      }
    },
    startProgress() {
      this.progressTask = FakeProgressLineTaskEnum.START_PROGRESS;
    },
    stopProgress() {
      this.progressTask = FakeProgressLineTaskEnum.STOP_PROGRESS;
    },
    async $_getAllData() {
      let allItems = [];
      let groupedAnns = await this.prepareAnnotations();
      groupedAnns = this.getGroupedByChapterAnns(groupedAnns);
      const chaptersNumber = Object.keys(groupedAnns).length;
      const isChaptersShown = chaptersNumber !== 0 && !this.activeCategory;

      Object.keys(groupedAnns).forEach(chapter => {
        const chapterAnnNumber = groupedAnns[chapter].length;
        allItems.push(
          new TabItemViewBuider()
            .setShownType(DATA_SHOWN_TYPES.CHAPTER_NAME)
            .setIsShown(isChaptersShown)
            .setIsFiltered(true)
            .setComponent(ChapterItem)
            .setExternalData({
              chapterName: chapter,
              chapterAnnNumber: chapterAnnNumber,
              chaptersNumber,
              id: chapter
            })
            .build()
        );
        groupedAnns[chapter].forEach(annotation => {
          allItems.push(
            new TabItemViewBuider()
              .setShownType(DATA_SHOWN_TYPES.ANNOTATION)
              .setIsShown(this.$_isAnnShown(annotation, chaptersNumber))
              .setIsFiltered(true)
              .setComponent(AnnotationChipItem)
              .setAnnotation(annotation)
              .setExternalData({
                id: annotation.id
              })
              .build()
          );
        });
      });

      return allItems;
    },
    async prepareAnnotations() {
      return Promise.all(this.ownAnnotations.map(this.getPreparedAnnotation));
    },
    async getPreparedAnnotation(annotation) {
      annotation.chapter = this.getChapterName(
        this.bookId,
        annotation.paragraphId
      );
      annotation.categoryLabel = this.getCategoryLabel(annotation.categoryId);
      annotation.categoryName = this.getCategoryName(annotation.categoryId);
      annotation.categoryColor = this.getCategoryColor(annotation.categoryId);
      annotation.annClass = this.getAnnClass(annotation.categoryId);
      annotation.highlightedQuote = '';
      annotation.paraId = this.getParaNum(this.bookId, annotation.paragraphId);
      return annotation;
    },
    $_applyFlattenedAnnotations() {
      this.flattenedAnnotations = this.getFlattenedAnns(this.bookId);
    },
    async $_subscribe() {
      this.endSubscription = this.$store.subscribe(async mutation => {
        switch (mutation.type) {
          case 'AnnotationsStore/setAnnotations':
          case 'AnnotationsStore/addAnnotation':
          case 'AnnotationsStore/deleteAnnotationProcess':
          case 'AnnotationsStore/deleteAnnotationsByCategory':
          case 'CategoriesStore/changeCategory':
            await this.$_reapplyAnnotations();
            if (this.isFilterActive) {
              this.$_hideChaptersAndShowNotes();
              this.$_filterAllItems();
            }
            break;
          case 'AnnotationsStore/changeAnnotation':
            this.$_changeAnnotationItem(mutation.payload.annotation);
            if (this.isFilterActive) {
              this.$_hideChaptersAndShowNotes();
              this.$_filterAllItems();
            }
            break;
        }
      });
    },
    async $_reapplyAnnotations() {
      this.$_applyFlattenedAnnotations();
      this.allItems = await this.$_getAllData();
      await this.$_fillAllAnnotations();
    },
    $_changeAnnotationItem(changedAnnotation) {
      const index = this.flattenedAnnotations.findIndex(
        annotation => annotation.id === changedAnnotation.id
      );
      this.flattenedAnnotations[index]
        .changeNote(changedAnnotation.note)
        .changeCategory(changedAnnotation.categoryId)
        .changeCategoryColor(
          this.getCategoryColor(changedAnnotation.categoryId)
        )
        .changeCategoryLabel(
          this.getCategoryLabel(changedAnnotation.categoryId)
        )
        .changeCategoryName(this.getCategoryName(changedAnnotation.categoryId))
        .changeCreationTime(changedAnnotation.createdAt);
    },
    async processAction(action) {
      switch (action.type) {
        case NotesTabActionsEnum.TOGGLE_ANNS: {
          this.$_toggleChapterAnns(action);
          break;
        }
        default: {
          logger.warn(`Unsupported notes tab action type: ${action.type}`);
          break;
        }
      }
    },
    async highlightAllAnns() {
      for (let elem of this.allItems) {
        if (
          elem.type === DATA_SHOWN_TYPES.ANNOTATION &&
          !elem.annotation?.highlightedQuote
        ) {
          await this.highlightAnn(elem.annotation);
        }
      }
    },
    isOneChapter() {
      return (
        this.allItems.filter(
          item => item.type === DATA_SHOWN_TYPES.CHAPTER_NAME
        )?.length <= 1
      );
    },
    async onFilter(action) {
      try {
        this.startProgress();
        const { category, filterText } = action;
        if (!this.isAllAnnotationsHighlighted) {
          await this.highlightAllAnns();
          this.isAllAnnotationsHighlighted = true;
        }

        this.activeCategory = category;
        this.filterText = filterText.trim();

        if (this.activeCategory || this.filterText) {
          this.$_hideChaptersAndShowNotes();
          this.$_filterAllItems();
          return;
        } else {
          this.$_unFilterAllItems();
        }

        if (this.isOneChapter()) {
          this.$_showChaptersAndNotes();
          return;
        }

        this.$_hideNotesAndShowChapters();
      } catch (error) {
        logger.error(`Get error on filter annotation: ${error}`);
      } finally {
        this.stopProgress();
      }
    },
    $_hideChaptersAndShowNotes() {
      this.allItems.filter(item => {
        if (item.type === DATA_SHOWN_TYPES.ANNOTATION) {
          item.isShown = true;
        } else if (item.type === DATA_SHOWN_TYPES.CHAPTER_NAME) {
          item.isShown = false;
        }
      });
    },
    $_showChaptersAndNotes() {
      this.allItems.filter(item => {
        item.isShown = true;
        item.isFiltered = true;
      });
    },
    $_hideNotesAndShowChapters() {
      this.allItems.filter(item => {
        if (item.type === DATA_SHOWN_TYPES.ANNOTATION) {
          item.isShown = false;
        } else if (item.type === DATA_SHOWN_TYPES.CHAPTER_NAME) {
          item.isShown = true;
        }

        item.isFiltered = true;
      });
    },
    $_isAnnShown(annotation, chaptersNumber) {
      let isShown = false;

      if (chaptersNumber <= 1) {
        isShown = this.isFilterActive ? this.$_isAnnFiltered(annotation) : true;
        return isShown;
      }

      if (this.activeChapter) {
        isShown = annotation.chapter === this.activeChapter;
        return isShown;
      }

      if (this.isFilterActive) {
        isShown = this.$_isAnnFiltered(annotation);
      }

      return isShown;
    },
    $_showChapterNames() {
      this.allItems.forEach(item => {
        if (item.type === DATA_SHOWN_TYPES.CHAPTER_NAME) {
          item.isShown = true;
        }
      });
    },
    $_toggleChapterAnns(action) {
      this.$_closePreviousActiveChapterIfNeeded(action);
      const annotationsToToggle = this.$_filterAnnotationsByChapterId(
        action.id
      );
      this.$_toggleAnns(annotationsToToggle);
    },
    $_closePreviousActiveChapterIfNeeded(action) {
      const isSameChapter =
        this.activeChapter && this.activeChapter === action.id;
      if (!isSameChapter) {
        const previousActiveChapters = this.$_filterAnnotationsByChapterId(
          this.activeChapter
        );
        this.$_toggleExpandedClass(action.chapterEl);
        this.$_closeChapterAnns(previousActiveChapters);
        this.activeChapter = action.id;
        return;
      }

      if (!this.activeChapter) {
        this.activeChapter = action.id;
        return;
      }

      this.activeChapter = '';
      action.chapterEl.classList.remove('expanded');
    },
    $_toggleExpandedClass(elToToggle) {
      this.$_removeExpandedClass();
      elToToggle.classList.toggle('expanded');
    },
    $_removeExpandedClass() {
      const expandedChapter = document.querySelector('.chapter-block.expanded');
      if (expandedChapter) {
        expandedChapter.classList.remove('expanded');
      }
    },
    $_closeChapterAnns(items) {
      items.forEach(elem => (elem.isShown = false));
    },
    $_filterAnnotationsByChapterId(chapterId) {
      return this.allItems.filter(item => {
        if (item.hasOwnProperty(DATA_SHOWN_TYPES.ANNOTATION)) {
          return item.annotation?.chapter === chapterId;
        }
      });
    },
    $_getAnnotationsToToggle(action) {
      return this.allItems.filter(item => {
        if (item.hasOwnProperty(DATA_SHOWN_TYPES.ANNOTATION)) {
          return item.annotation?.chapter === action.id;
        }
      });
    },
    async highlightAnn(annotation) {
      const highlightedQuote = await this.$_getHighlightedQuote(
        this.bookId,
        annotation
      );
      annotation.highlightedQuote = highlightedQuote;
    },
    $_toggleAnns(items) {
      try {
        for (let elem of items) {
          if (!elem.annotation.highlightedQuote) {
            this.highlightAnn(elem.annotation);
          }
          elem.isShown = !elem.isShown;
        }
      } catch (error) {
        logger.error(`Get error on toggle annotation: ${error}`);
      }
    },
    $_filterAllItems() {
      this.lastShownIndex = SHOW_ANNS_NUM;
      this.allItems.forEach(item => {
        if (item.type === DATA_SHOWN_TYPES.ANNOTATION) {
          let annotation = item.annotation;
          item.isFiltered = this.$_isAnnFiltered(annotation);
        }
      });
    },
    $_unFilterAllItems() {
      this.allItems.forEach(item => {
        if (item.type === DATA_SHOWN_TYPES.ANNOTATION) {
          item.isFiltered = false;
        }
      });
    },
    $_isAnnFiltered(annotation) {
      const isValidText = this.$_filterByText(annotation);
      const isValidCategory = this.activeCategory
        ? this.activeCategory === annotation?.categoryName
        : true;
      return isValidText && isValidCategory;
    },
    $_filterByText(annotation) {
      return (
        this.$_checkIfFilteredByText(annotation.note) ||
        this.$_checkIfFilteredByText(
          this.$_getTextFromHTML(annotation.highlightedQuote)
        )
      );
    },
    $_getTextFromHTML(highlightedQuote) {
      let element = document.createElement('div');
      element.innerHTML = highlightedQuote.text;
      return element.innerText;
    },
    $_checkIfFilteredByText(annText = '') {
      return annText.toLowerCase().includes(this.filterText.toLowerCase());
    },
    getGroupedByChapterAnns(annotations) {
      const reg = /\d+/g;
      const groupedAns = groupBy(annotations, 'chapter');
      Object.keys(groupedAns).forEach(chapter => {
        groupedAns[chapter] = groupedAns[chapter].sort(
          (a, b) => a.paragraphId.match(reg) - b.paragraphId.match(reg)
        );
      });
      return groupedAns;
    },
    onExtrasScroll(data) {
      let realScrollHeight = data.target.scrollTop + data.target.offsetHeight;
      if (data.target.scrollHeight - realScrollHeight < HEIGHT_TO_START_LOAD) {
        this.lastShownIndex += SHOW_ANNS_NUM;
        return;
      }
    }
  }
};
</script>

<style lang="less" src="./NotesTab.less"></style>
