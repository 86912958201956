import DB from '@/services/Agent/dao/DB';

async function get() {
  const docId = _getDocId();
  return DB.userRW().get(docId);
}

function getDefaultDoc() {
  return {
    _id: _getDocId(),
    type: 'notehashtags',
    noteHashtags: [],
    createdAt: Date.now(),
    modifiedAt: Date.now()
  };
}

function write(noteHashtags) {
  return DB.userRW().put(noteHashtags);
}

function _getDocId() {
  const userId = DB.userId();
  return DB.id.notehashtags(userId);
}

export default {
  get,
  getDefaultDoc,
  write
};
