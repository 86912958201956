<template>
  <div class="extended-annotation" :dir="dir" :annotation-id="annotation.id">
    <div class="title-annotation">
      <div class="title-annotation-wrapper">
        <span class="circle-icon" :class="categoryClass" />
        <span class="title-annotation-text">{{ categoryName }}</span>
      </div>
      <AnnotationActionButtons
        :annotation="annotation"
        :editable="editable"
        :quote="annHighlightedQuote"
      />
    </div>
    <AnnotationChipItem
      :annotation="annotation"
      chip-content-class="no-hover"
      :click-action="false"
    />
    <div
      v-if="annotation.note"
      class="note-text"
      dir="auto"
      v-html="annotation.note"
    ></div>
    <div v-if="annotationTags.length" class="additional-tags">
      <div class="additional-tags-title">
        {{ $t('NoteHashtags.label') }}
      </div>
      <div class="additional-tags-list">
        <span v-for="tag in annotationTags" :key="tag.id">
          {{ tag.name }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import AnnotationActionButtons from '@/components/views/AnnotationActionButtons/AnnotationActionButtons.vue';
import highlightedQuoteMixin from '@/components/mixins/highlightedQuoteMixin';
import AnnotationChipItem from '@/components/views/Extras/NotesTab/AnnotationChipItem/AnnotationChipItem';

export default {
  name: 'ExtendedAnnotation',
  components: { AnnotationActionButtons, AnnotationChipItem },
  mixins: [highlightedQuoteMixin],
  props: {
    annotation: {
      type: Object,
      required: true
    },
    maxNoteLength: {
      type: Number,
      required: false,
      default: 30
    },
    editable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      annHighlightedQuote: ''
    };
  },
  computed: {
    annotationTags() {
      return this.annotation.noteHashtags;
    },
    bookId() {
      const openParams = this.$store.getters[
        'OpenParameterStore/getPublicationOpenParameters'
      ];
      return openParams?.publicationId;
    },
    categoryName() {
      return this.$t(this.getCategoryLabelById) || this.getCategoryNameById;
    },
    categoryLabel() {
      return this.$store.getters['CategoriesStore/getCategoryLabelById'](
        this.annotation.categoryId
      );
    },
    getCategoryNameById() {
      return this.$store.getters['CategoriesStore/getCategoryNameById'](
        this.annotation.categoryId
      );
    },
    categoryClass() {
      return this.$store.getters['CategoriesStore/getAnnClassByCategoryId'](
        this.annotation.categoryId
      );
    },
    dir() {
      return this.annotation.dir;
    }
  },
  watch: {
    annotation() {
      this.setQuote();
    }
  },
  mounted() {
    this.setQuote();
  },
  methods: {
    async setQuote() {
      const highlightedQuote = await this.$_getHighlightedQuote(
        this.bookId,
        this.annotation
      );
      this.annHighlightedQuote = highlightedQuote?.text || '';
    }
  }
};
</script>

<style lang="less" src="./ExtendedAnnotation.less" />
