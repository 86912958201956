import RequestService from '@/services/RequestService';

function processAddNoteHashtagsRequest(noteHashtags) {
  return RequestService.request(
    'post',
    'NotesHashtagsController',
    'set',
    noteHashtags
  );
}

export default {
  processAddNoteHashtagsRequest
};
