import Utils from '@/services/utils/Utils';
import now from 'lodash/now';
import Locator from '@shared/publication/locator';
import MaterialTypes from '@/enums/MaterialTypes';
import MaterialPositions from '@/enums/MaterialPositions';
class RawQuestionBuilder {
  setTestId(testId) {
    this.testId = testId;
    return this;
  }

  setQuestion(question) {
    this.question = question;
    return this;
  }

  setAnswer(answer) {
    this.answer = answer;
    return this;
  }

  setIncorrectAnswers(incorrectAnswers) {
    this.incorrectAnswers = incorrectAnswers;
    return this;
  }

  setType(type) {
    this.type = type;
    return this;
  }

  build() {
    return new RawQuestion(this);
  }
}

class RawQuestion {
  constructor(rawQuestion) {
    // this._id = Utils.uuid();
    this.testId = rawQuestion.testId;
    this.question = rawQuestion.question || '';
    this.answer = rawQuestion.answer || '';
    this.incorrectAnswers = rawQuestion.incorrectAnswers || {
      0: '',
      1: '',
      2: ''
    };
    this.type = rawQuestion.type || 'FlashcardStudy';
  }
}

class RawParaSummaryBuilder {
  setText(text) {
    this.text = text;
    return this;
  }

  setParaId(paraId) {
    this.paraId = paraId;
    return this;
  }

  setCompletedAt(completedAt) {
    this.completedAt = completedAt;
    return this;
  }

  build() {
    return new RawParaSummary(this);
  }
}

class RawParaSummary {
  constructor(paraSummary) {
    this.text = paraSummary.text || '';
    this.completedAt = paraSummary.completedAt;
    this.locator = {
      index: 1,
      paragraphId: paraSummary.paraId,
      type: 'B'
    };
  }

  changeText(text) {
    this.text = text;
    this.completedAt = now();
  }
}

class RawEssayTaskBuilder {
  setWordsLimit(limit) {
    this.wordsLimit = limit;
    return this;
  }

  setTopic(topic) {
    this.topic = topic;
    return this;
  }

  setComment(comment) {
    this.comment = comment;
    return this;
  }

  setPublicationId(pubId) {
    this.publicationId = pubId;
    return this;
  }

  setParagraphId(paragraphId) {
    this.paragraphId = paragraphId;
    return this;
  }

  setCreatedAt(createdAt) {
    this.createdAt = createdAt;
    return this;
  }

  setId(id) {
    this.id = id;
    return this;
  }

  build() {
    return new RawEssayTask(this);
  }
}

class RawEssayTask {
  constructor(essay) {
    this._id = essay.id;
    this.createdAt = essay.createdAt;
    this.wordsLimit = essay.wordsLimit;
    this.topic = essay.topic;
    this.comment = essay.comment;
    this.locator = {
      type: 'B',
      paragraphId: essay.paragraphId,
      index: 1
    };
    this.publicationId = essay.publicationId;
  }
}

class RawParaNoteBuilder {
  setParagraphId(paragraphId) {
    this.paragraphId = paragraphId;
    return this;
  }

  setNote(note) {
    this.note = note;
    return this;
  }

  setPosition(position) {
    this.position = position;
    return this;
  }

  setCreatedAt(createdAt) {
    this.createdAt = createdAt;
    return this;
  }

  setModifiedAt(modifiedAt) {
    this.modifiedAt = modifiedAt;
    return this;
  }

  setId(id) {
    this.id = id;
    return this;
  }

  build() {
    return new RawParaNote(this);
  }
}

class RawParaNote {
  constructor(paraNote) {
    this.category = 1;
    this.id = Utils.uuid();
    this.createdAt = paraNote.createdAt || now();
    this.modifiedAt = paraNote.modifiedAt || now();
    this.paragraphId = paraNote.paragraphId;
    this.note = paraNote.note || '';
    this.position = paraNote.position || MaterialPositions.BEFORE_PARAGRAPH;
    this.studyGuide = true;
  }

  changeNote(note) {
    this.note = note;
    return this;
  }

  changePosition(position) {
    this.position = position;
    return this;
  }

  changeId(id) {
    this.id = id;
  }
}

class RawAnnotationBuilder {
  setStart(start) {
    this.start = start;
    return this;
  }

  setEnd(end) {
    this.end = end;
    return this;
  }

  setCategory(category) {
    this.category = category;
    return this;
  }

  setBlockId(blockId) {
    this.blockId = blockId;
    return this;
  }

  setNote(note) {
    this.note = note;
    return this;
  }

  setCreatedAt(createdAt) {
    this.createdAt = createdAt;
    return this;
  }

  setModifiedAt(modifiedAt) {
    this.modifiedAt = modifiedAt;
    return this;
  }

  setId(id) {
    this.id = id;
    return this;
  }

  setStudyGuide(isStudyGuide) {
    this.studyGuide = isStudyGuide;
    return this;
  }

  build() {
    return new RawAnnotation(this);
  }
}

class RawAnnotation {
  constructor(annotation) {
    this.id = annotation.id || Utils.uuid();
    this.type = annotation.type || MaterialTypes.ANNOTATION;
    this.createdAt = annotation.createdAt || now();
    this.modifiedAt = annotation.modifiedAt || now();
    this.blockId = annotation.blockId;
    this.start = Locator.serialize(annotation.start);
    this.end = Locator.serialize(annotation.end);
    this.category = annotation.category;
    this.categoryId = annotation.categoryId || annotation.category;
    this.note = annotation.note;
    this.studyGuide = annotation.studyGuide;
    this.isDeleted = annotation.isDeleted;
    this.noteHashtags = annotation.noteHashtags || [];
  }
}

class RawBookmark {
  constructor(bookmark) {
    this.id = bookmark.id || Utils.uuid();
    this.type = bookmark.type || MaterialTypes.BOOKMARK;
    this.createdAt = bookmark.createdAt || now();
    this.blockId = bookmark.blockId;
    this.locator = bookmark.locator.toJSON();
    this.paraNum = bookmark.paraNum;
    this.paragraphId = bookmark.paragraphId;
  }
}

class RawNoteHashtag {
  constructor(noteHashtag) {
    this.id = noteHashtag.id || Utils.uuid();
    this.type = noteHashtag.type || MaterialTypes.NOTE_HASHTAG;
    this.name = noteHashtag.name || '';
    this.createdAt = noteHashtag.createdAt || now();
    this.modifiedAt = noteHashtag.modifiedAt || now();
  }
}

class RawTestBuilder {
  setId(id) {
    this.id = id;
    return this;
  }

  setTestType(type) {
    this.type = type;
    return this;
  }

  setQuestionsCount(count) {
    this.testQuestionsCount = count;
    return this;
  }

  setPublicationId(publicationId) {
    this.publicationId = publicationId;
    return this;
  }

  setParagraphNumber(paraNumber) {
    this.paragraphNumber = paraNumber;
    return this;
  }

  setOriginalId(originalId) {
    this.originalId = originalId;
    return this;
  }

  setName(name) {
    this.name = name;
    return this;
  }

  setCreatedAt(createdAt) {
    this.createdAt = createdAt;
    this.modifiedAt = createdAt;
    return this;
  }

  setLocator(locator) {
    this.paragraphId = locator;
    return this;
  }

  setDescription(description) {
    this.description = description;
    return this;
  }

  setTestQuestions(questions) {
    this.testQuestions = questions;
    return this;
  }

  build() {
    return new RawTest(this);
  }
}

class RawTest {
  constructor(test) {
    this.anchor = true;
    this.dataAttr =
      'data-selectable=none data-test-para="para_3" data-test-id="683ccf84-5ac1-4f79-9361-0ad492975680"';
    this.createdAt = test.createdAt || now();
    this.description = test.description || '';
    this.isEditor = true;
    const title =
      test.type === MaterialTypes.QUIZ
        ? 'ManageTest.questions.label'
        : 'ManageTest.flashcards.label';
    this.progress = {
      className: 'not_selected',
      current: 0,
      incorrect: 0,
      title
    };
    this.state = 'Edit';
    this.locator = test.paragraphId;
    this.modifiedAt = test.createdAt || now();
    this.name = test.name || '';
    this.originalId = test.originalId;
    this.paragraphNumber = test.paragraphNumber;
    this.publicationId = test.publicationId;
    this.testQuestionsCount = test.testQuestionsCount || 0;
    this.testType = test.type;
    this.testQuestions = test.testQuestions || [];
    this.type = 'Test';
    this._id = test.id || Utils.uuid();
  }
}

class DefaultRawCategory {
  constructor(category) {
    this.isDefault = true;
    this.id = category.id;
    this.color = category.color;
    this.editorOnly = category.editorOnly;
    this.name = category.name;
    this.nightColor = category.nightColor;
    this.nonLocalizedName = category.nonLocalizedName;
    this.preset = category.preset;
  }
}

class RawNewCategory {
  constructor(category) {
    this.isDefault = false;
    this.id = category.id;
    this.color = category.color;
    this.editorOnly = category.editorOnly;
    this.name = category.name;
    this.nightColor = category.nightColor;
    this.underline = category.underline;
    this.version = category.version;
    this.modifiedAt = category.modifiedAt;
    this.isDeleted = category.isDeleted;
  }
}

function createRawQuestionBuilder() {
  return new RawQuestionBuilder();
}

function createRawParaSummaryBuilder() {
  return new RawParaSummaryBuilder();
}

function createRawEssayTaskBuilder() {
  return new RawEssayTaskBuilder();
}

function createRawParaNoteBuilder() {
  return new RawParaNoteBuilder();
}

function createRawAnnotationBuilder() {
  return new RawAnnotationBuilder();
}

function createRawTestBuilder() {
  return new RawTestBuilder();
}

function createRawEssayTask(essayTask) {
  return new RawEssayTask(essayTask);
}

function createRawAnnotation(annotation) {
  return new RawAnnotation(annotation);
}

function createRawBookmark(bookmark) {
  return new RawBookmark(bookmark);
}

function createRawNoteHashtag(noteHashtag) {
  return new RawNoteHashtag(noteHashtag);
}

function createRawParaNote(paraNote) {
  return new RawParaNote(paraNote);
}

function createRawTest(test) {
  return new RawTest(test);
}

function createRawNewCategory(category) {
  return new RawNewCategory(category);
}

function createRawDefaultCategory(category) {
  return new DefaultRawCategory(category);
}

export default {
  createRawQuestionBuilder,
  createRawParaSummaryBuilder,
  createRawEssayTaskBuilder,
  createRawParaNoteBuilder,
  createRawAnnotationBuilder,
  createRawTestBuilder,

  createRawDefaultCategory,
  createRawNewCategory,
  createRawEssayTask,
  createRawAnnotation,
  createRawBookmark,
  createRawParaNote,
  createRawTest,
  createRawNoteHashtag
};
